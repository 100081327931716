.app-rte {
  img {
    max-width: 100%;
  }
  video {
    max-width: 100%;
  }
  span[class^="ImageSpan"] {
    width: 100% !important;
    height: 100px !important;
    line-height: initial !important;
    font-size: initial !important;
    letter-spacing: initial !important;
    background-size: contain !important;
  }
  p {
    margin: 0,
  }
}

